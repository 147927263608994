import CustomCell from 'src/components/Table/CustomCell';
import { sortingMethods } from 'src/constants/table';

export const topHeading = [
  {
    title: 'Application Details',
    colSpan: 3,
    borderRight: true,
  },
  {
    title: 'Affected Devices',
    colSpan: 3,
    borderRight: true,
  },
  {
    title: 'Launch Attempts',
    colSpan: 3,
    borderRight: true,
  },
  {
    title: '',
    colSpan: 1,
  },
];

export const secondHeadings = [
  {
    title: 'Name',
    name: 'arpDisplayName',
    hasSorting: true,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'arp_display_name',
    sortFieldName: 'OrderWdacQuery',
    width: '14%',
  },
  {
    title: 'Version',
    name: 'arpDisplayVersion',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'arp_display_version',
    sortFieldName: 'OrderWdacQuery',
    width: '12%',
  },
  {
    title: 'Publisher',
    name: 'arpPublisher',
    borderRight: true,
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'arp_publisher',
    sortFieldName: 'OrderWdacQuery',
    width: '12%',
  },
  {
    title: '24 Hours',
    name: 'devicesLast24Hours',

    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'devices_last24hours',
    sortFieldName: 'OrderWdacQuery',
    width: '8%',
  },
  {
    title: '30 Days',
    name: 'devicesLast30Days',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'devices_last30days',
    sortFieldName: 'OrderWdacQuery',
    width: '8%',
  },
  {
    title: '90 Days',
    name: 'devicesLast90Days',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'devices_last90days',
    sortFieldName: 'OrderWdacQuery',
    borderRight: true,
    width: '8%',
  },
  {
    title: '24 Hours',
    name: 'estimatedLaunchAttemptsLast24Hours',

    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'estimated_launch_attempts_last24hours',
    sortFieldName: 'OrderWdacQuery',
    width: '8%',
  },
  {
    title: '30 Days',
    name: '',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'estimated_launch_attempts_last30days',
    sortFieldName: 'OrderWdacQuery',
    width: '8%',
  },
  {
    title: '90 Days',
    name: 'estimatedLaunchAttemptsLast90Days',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'estimated_launch_attempts_last90days',
    sortFieldName: 'OrderWdacQuery',
    borderRight: true,
    width: '8%',
  },
  {
    title: 'Managed App Match Count',
    name: 'managedApplicationMatchCount',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'device_wdac_events_count',
    sortFieldName: 'OrderWdacQuery',
    width: '14%',
  },
];

export const columnsOrder = [
  { name: 'arpDisplayName' },
  'arpDisplayVersion',
  { name: 'arpPublisher' },
  { name: 'devicesLast24Hours', isNumber: true },
  { name: 'devicesLast30Days', isNumber: true },
  { name: 'devicesLast90Days', isNumber: true },
  { name: 'estimatedLaunchAttemptsLast24Hours', isNumber: true },
  { name: 'estimatedLaunchAttemptsLast30Days', isNumber: true },
  { name: 'estimatedLaunchAttemptsLast90Days', isNumber: true },
  { name: 'managedApplicationMatchCount', isNumber: true },
];

export const VISIBLE_DEVICES_COUNT = 5;

export const WDAC_APP_PREVIOUS_PATH = 'wdacAppPreviousPath';
