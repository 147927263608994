import { useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TableHeading from '../../components/Table/TableHeading';
import { columnsOrder, deviceStatuses, headings } from './constants';
import LoadingRows from '../../components/Table/LoadingRows';
import Row from '../../components/Table/Row';
import useDebounceHandler from '../../hooks/useDebounceHandler';
import useQueryParams from '../../hooks/useQueryParams';
import useQueryData from '../../queries/useQueryData';
import { DEVICES_QUERY_KEY, USER_QUERY_KEY } from '../../constants/query';
import { activeCustomerEnvIdSelector } from '../../selectors/user';
import FiltersSidebar from './FilterSidebar';
import Iconify from '../../components/Iconify';
import useTableSort from '../../hooks/useTableSort';
import { defaultDeviceFilters } from '../../components/Filters/constants';
import NoDataCell from '../../components/NoDataCell';
import { pagesText } from '../../constants/pagesText';
import GenericTableCell from '../../components/GenericTableCell';
import useMutateDeviceStatus from '../../queries/devices/useMutateDeviceStatus';
import DeviceTagModal from './DeviceTagModal';
import useDialog from '../../hooks/useDialog';
import IconButton from '../../components/IconButton';
import { checkHasFilters } from '../../utils/table.util';
import useResetSearchParams from '../../hooks/useResetSearchParams';
import TagService from '../../services/tag.service';
import useRemoveListTag from '../../queries/devices/useRemoveListTag';
import TableContainer from '../../components/Table/TableContainer';
import useFetchDevices from '../../queries/useFetchDevices';
import { formatDate } from '../../utils/formatTime';
import Chip from '@mui/material/Chip';
import FilterCount from 'src/components/Filters/FilterCount';
import { defaultSearchParams, reversedSortMap, sortingMethods } from 'src/constants/table';
import TableSearch from 'src/components/elements/TableSearch';
import { Box } from '@mui/material';

const StyledSelect = styled(Select)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  '& svg': {
    fill: '#fff',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fff',
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  border: '0.5px solid #fff',
  backgroundColor: '#3f3f3f',
  fontSize: theme.typography.pxToRem(12),
  '&:hover': {
    backgroundColor: '#3f3f3f',
  },
  '& .MuiChip-deleteIcon': {
    fill: '#fff',
    color: '#fff',

    '&:hover': {
      fill: '#fff',
      color: '#fff',
    },
  },
}));

const DevicesTable = () => {
  const customerEnvId = useQueryData(USER_QUERY_KEY, activeCustomerEnvIdSelector);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [activeTags, setActiveTags] = useState([]);
  useResetSearchParams();

  const deviceStatusMutation = useMutateDeviceStatus();
  const removeTagMutation = useRemoveListTag();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const {
    open: isEditTagDialogOpen,
    onOpen: onEditTagDialogOpen,
    onClose: onEditTagDialogClose,
  } = useDialog();

  const {
    queryObject: { page, rowsPerPage, searchValue: initialSearchValue, filters = {} },
    changeFieldInURL,
    onApplySearchParams,
  } = useQueryParams({ defaultRowsPerPage: 5 });
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const [activeDeviceId, setActiveDeviceId] = useState(null);

  const {
    query,
    isLoading,
    data: devices,
    lastElementRef,
    isFetching,
    count,
  } = useFetchDevices({});

  const { onSort, onSortTable, onResetSortField, getListItemSortMethod, getCellSortMethod } =
    useTableSort({
      filters,
      onApplySearchParams,
      page,
      rowsPerPage,
      searchValue,
      defaultFilters: defaultDeviceFilters,
    });

  const onCloseFilters = useCallback(() => {
    setIsOpenFilters(false);
  }, [setIsOpenFilters]);

  const onOpenFilters = useCallback(() => {
    setIsOpenFilters(true);
  }, [setIsOpenFilters]);

  const debounceQueryHandler = useDebounceHandler((key, value) => {
    changeFieldInURL([
      { key, value },
      { key: 'page', value: 1 },
    ]);
  }, 500);

  const onChangeSearchValue = useCallback(
    event => {
      setSearchValue(event.target.value);
      debounceQueryHandler('search', event.target.value.toLowerCase());
    },
    [debounceQueryHandler, setSearchValue],
  );

  const onResetSearch = useCallback(() => {
    setSearchValue('');
    changeFieldInURL([defaultSearchParams]);
  }, [setSearchValue, changeFieldInURL]);

  const onApplyFilters = useCallback(
    filters => {
      onApplySearchParams({ filters, page: 1, limit: rowsPerPage, searchValue });
    },
    [onApplySearchParams, rowsPerPage, searchValue],
  );

  const deviceTagMutation = useMutation(TagService.editDeviceTags);

  const onCellSort = useCallback(
    ({ field, sortMethod, sortingCellField, sortFieldName }) => {
      const reversedSortOrder =
        sortMethod === sortingMethods.initial ? sortingMethods.desc : reversedSortMap[sortMethod];
      onSort(field, reversedSortOrder, sortingCellField, sortFieldName);
    },
    [onSort],
  );

  const onChangeDeviceStatus = useCallback(
    (deviceId, status) => {
      deviceStatusMutation.mutate({
        deviceId,
        status,
        search: initialSearchValue,
        query,
      });
    },
    [deviceStatusMutation, initialSearchValue, query],
  );

  const onUpdateDeviceTag = useCallback(
    ({ tagNames, tagIds }) => {
      deviceTagMutation.mutate(
        {
          tagNames: tagNames,
          deviceId: activeDeviceId,
          customerEnvId,
          tagIds: tagIds,
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries([DEVICES_QUERY_KEY]);

            onEditTagDialogClose();

            enqueueSnackbar(pagesText.devices.successFullyUpdateDeviceTag, {
              variant: 'success',
              autoHideDuration: 3000,
            });
          },
          onError: () => {
            enqueueSnackbar(pagesText.somethingWentWrong, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          },
        },
      );
    },
    [
      enqueueSnackbar,
      queryClient,
      customerEnvId,
      deviceTagMutation,
      activeDeviceId,
      onEditTagDialogClose,
    ],
  );

  const onDeleteTag = useCallback(
    ({ tagId, tagSearch }) => {
      removeTagMutation.mutate({
        tagId,
        search: tagSearch,
        deviceSearch: initialSearchValue,
        deviceId: activeDeviceId,
        query,
      });
    },
    [removeTagMutation, activeDeviceId, initialSearchValue, query],
  );

  const onEditIconClick = useCallback(
    (deviceId, tags) => {
      setActiveDeviceId(deviceId);
      setActiveTags(tags);
      onEditTagDialogOpen();
      onApplySearchParams({ filters, page: 1, limit: rowsPerPage, searchValue });
    },
    [
      onEditTagDialogOpen,
      setActiveDeviceId,
      setActiveTags,
      onApplySearchParams,
      filters,
      rowsPerPage,
      searchValue,
    ],
  );

  const hasFilters = useMemo(() => checkHasFilters(filters), [filters]);

  const renderCustomCell = useCallback(
    (column, row) => {
      if (column === 'tagName') {
        return (
          <TableCell>
            <Stack
              direction="row"
              alignItems={'center'}
              spacing={1}
              sx={{ minWidth: '100px', fontSize: '12px' }}>
              {!!row.tags.length ? (
                <Stack direction="row" alignItems={'center'} gap={1} flexWrap={'wrap'}>
                  {row.tags.map(tag => (
                    <StyledChip key={tag.id} label={tag.name} />
                  ))}
                </Stack>
              ) : (
                'no data'
              )}
              <IconButton
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  onEditIconClick(row.id, row.tags, row.tagId);
                }}>
                <Iconify icon="ic:baseline-edit" color="#a2a2a2" width={18} height={18} />
              </IconButton>
            </Stack>
          </TableCell>
        );
      }

      if (column === 'status') {
        return (
          <TableCell
            onClick={e => {
              e.stopPropagation();
            }}>
            <StyledSelect
              slotProps={{
                input: {
                  sx: {
                    padding: '12px 8px',
                  },
                },
              }}
              value={row.status.toLowerCase()}
              label=""
              onChange={event => onChangeDeviceStatus(row.id, event.target.value)}>
              {deviceStatuses.map(status => (
                <MenuItem value={status.value}>{status.label}</MenuItem>
              ))}
            </StyledSelect>
          </TableCell>
        );
      }

      if (column === 'lastContactDate') {
        return (
          <GenericTableCell
            key={row[column?.name || column]}
            value={formatDate(row[column?.name || column])}
            styles={column?.styles || {}}
            tooltipText={column?.tooltipText}
          />
        );
      }

      return (
        <GenericTableCell
          key={row[column?.name || column]}
          value={row[column?.name || column]}
          styles={column?.styles || {}}
          tooltipText={column?.tooltipText}
        />
      );
    },
    [onChangeDeviceStatus, onEditIconClick],
  );

  const closeEditTagDialog = useCallback(() => {
    onEditTagDialogClose();
  }, [onEditTagDialogClose]);

  const filteredCount = useMemo(() => {
    return !isLoading && (hasFilters || searchValue) ? count : null;
  }, [count, hasFilters, isLoading, searchValue]);

  return (
    <Box sx={{ position: 'relative' }} mt={4}>
      <FilterCount filteredCount={filteredCount} onOpenFilters={onOpenFilters} />
      <TableSearch
        searchValue={searchValue}
        onChangeSearchValue={onChangeSearchValue}
        onResetSearch={onResetSearch}
      />
      <TableContainer sx={{ maxHeight: 'calc(100vh - 248px)' }}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHeading
            headings={headings}
            hasFilter
            hasFilters={hasFilters}
            onOpenFilters={onOpenFilters}
            onSortTable={onSortTable}
            onResetSortField={onResetSortField}
            getListItemSortMethod={getListItemSortMethod}
            getCellSortMethod={getCellSortMethod}
            onCellSort={onCellSort}
            listCellStyles={{
              top: '0',
            }}
          />

          <TableBody sx={{ backgroundColor: '#4B4B4B' }}>
            {isLoading ? (
              <LoadingRows columnCount={columnsOrder.length} rowCount={5} />
            ) : (
              <>
                {devices?.map((row, index) => (
                  <Row
                    ref={devices.length - 1 === index ? lastElementRef : null}
                    key={row.id}
                    row={row}
                    hasActions={false}
                    columnData={columnsOrder}
                    renderCustomCell={renderCustomCell}
                    source="devices"
                    isClickable={true}
                  />
                ))}
                {!isLoading && isFetching && (
                  <LoadingRows columnCount={columnsOrder.length} rowCount={3} />
                )}
                {!isLoading && !devices?.length && (
                  <NoDataCell colSpan={columnsOrder.length + 1}>{pagesText.noDataFound}</NoDataCell>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <FiltersSidebar
        open={isOpenFilters}
        onClose={onCloseFilters}
        onApplyFilters={onApplyFilters}
        initialQueryFilters={filters}
      />
      <DeviceTagModal
        isOpen={isEditTagDialogOpen}
        onClose={closeEditTagDialog}
        onUpdateDeviceTag={onUpdateDeviceTag}
        loading={deviceTagMutation.isLoading || isFetching}
        activeTags={activeTags}
        onDeleteTag={onDeleteTag}
      />
    </Box>
  );
};

export default DevicesTable;
