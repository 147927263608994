import Page from 'src/components/Page';
import TabsPageLayout from 'src/components/TabsPageLayout';
import { pagesText } from 'src/constants/pagesText';
import { CUSTOMER_QUERY_KEY, WDAC_TABS_DATA_COUNTS_KEY } from 'src/constants/query';
import { PORTAL_ROUTE } from 'src/constants/routes';
import useQueryData from 'src/queries/useQueryData';
import { companyNameSelector } from 'src/selectors/customer';
import { wdacResultsTabs } from './constants';
import { Loader } from 'src/components';
import WDACResultsService from 'src/services/wdac-results.service';
import useSubscribeStorage from 'src/hooks/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';
import { useQuery } from 'react-query';

const WDACResults = () => {
  const companyName = useQueryData(CUSTOMER_QUERY_KEY, companyNameSelector);
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);

  const { data: counts, isLoading: countsIsLoading } = useQuery([WDAC_TABS_DATA_COUNTS_KEY], () =>
    WDACResultsService.getWdacResultsTabsDataCounts({
      customerEnvId,
    }),
  );

  if (countsIsLoading) {
    return <Loader />;
  }

  return (
    <Page
      title={pagesText.dashboard.title}
      breadcrumbs={[
        { label: companyName, route: `${PORTAL_ROUTE}/apps` },
        { label: 'WDAC Results' },
      ]}>
      <TabsPageLayout
        tabs={wdacResultsTabs(counts)}
        wrapperProps={{
          sx: {
            maxWidth: '100%',
          },
        }}
      />
    </Page>
  );
};

export default WDACResults;
